<template>
  <validation-observer ref="validacaoCnaePrincipal">
    <b-row>
      <b-col
        cols="3"
        style="padding-right:1rem"
      >
        <b-form-group
          label="CNAE principal"
          label-for="codeCnae"
        >
          <b-input
            id="codeCnae"
            v-model="valueIn.idIbge"
            :state="erro ? false:null"
            type="number"
            @blur="buscarDescricao()"
          />
          <small
            v-if="erro"
            class="text-danger"
          >Subclass da atividade economica não encontrado</small>
        </b-form-group>
      </b-col>
      <b-col
        cols="9"
        class="pl-0"
      >
        <b-form-group
          label="Descrição"
          label-for="cnaeDescricao"
        >
          <validation-provider
            #default="{ errors }"
            name="Cnae"
            :rules="valueIn.idIbge ? 'required' : ''"
          >
            <b-input
              id="cnaeDescricao"
              :value="valueIn.descricao"
              disabled
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import api from './api'

export default {
  name: 'CNAE',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      erro: false,
      valueIn: null,
    }
  },
  watch: {
    value(value, oldValue) {
      if (value !== oldValue) {
        this.valueIn = value
      }
    },
  },
  created() {
    this.valueIn = this.value
    if (!this.value.descricao) {
      this.valueIn.descricao = null
    }
  },
  methods: {
    buscarDescricao() {
      if (this.valueIn.idIbge === '') {
        this.$set(this.valueIn, 'descricao', null)
        this.$emit('input', this.valueIn)
      } else {
        this.erro = false
        api.buscarCnae(this.valueIn.idIbge)
          .then(payload => {
            const desc = payload.data.length === 0 ? null : payload.data[0].descricao
            if (!desc) {
              this.erro = true
            }
            this.$set(this.valueIn, 'descricao', desc)
            this.$emit('input', this.valueIn)
          })
          .catch(err => {
            this.erro = true
            this.$set(this.valueIn, 'descricao', null)
            this.$emit('input', this.valueIn)
            throw err
          })
      }
      this.$refs.validacaoCnaePrincipal.validate()
    },
  },
}
</script>
